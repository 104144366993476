import React, { useContext, useEffect, useRef, useState } from 'react';
import qs from 'query-string';
import { ThemeProvider } from 'styled-components';
import { Outlet } from 'react-router-dom';
import { mergeDeepRight } from 'ramda';
import { useSetRecoilState } from 'recoil';
import FrameSizeContext from '~/context/FrameSizeContext';
import HeightContext from '~/context/HeightContext';
import HostInfoContext from '~/context/HostInfoContext';
import TrackingContext, {
  DEFAULT_TRACKING_CONTEXT_VALUE,
} from '~/context/TrackingContext';
import WidgetContext, { IWidgetContext } from '~/context/WidgetContext';
import useWidgetContext from '~/hooks/useWidgetContext';
import useLocalStorageValue from '@bndl-io/use-local-storage';

import useFireTrackingEvent from '~/hooks/useFireTrackingEvent';
import useSetupAnalytics from '~/hooks/useSetupAnalytics';
import {
  ConsentParams,
  FrameSizeSelector,
  HostInfo,
  WindowConfig,
} from '~/shared/types';
import useMessaging from '~/hooks/useMessaging';
import serializeConsentOptions from '~/utils/serializeConsentOptions';
import windowConfigToWidgetContext from '~/utils/windowConfigToWidgetContext';
import getTheme from '~/theme';
import IngestParamsProvider from '../IngestParamsProvider';
import getStoredLocale from '../../utils/getStoredLocale';
import getBrowserLocale from '../../utils/getBrowserLocale';
import { consentSelector, localeSelector } from '../../state/widgetState';

export const AppWrapper: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const widgetContext = useWidgetContext();

  const headerRef = useRef<HTMLHRElement | null>(null);
  const footerRef = useRef<HTMLHRElement | null>(null);

  return (
    <IngestParamsProvider>
      <TrackingContext.Provider
        value={{
          ...DEFAULT_TRACKING_CONTEXT_VALUE,
          accountId: widgetContext.accountId,
        }}
      >
        <WithAnalytics>
          <WithSessionStart>
            <HeightContext.Provider value={{ footerRef, headerRef }}>
              <ThemeProvider theme={getTheme(widgetContext.styleConfig)}>
                {children}
              </ThemeProvider>
            </HeightContext.Provider>
          </WithSessionStart>
        </WithAnalytics>
      </TrackingContext.Provider>
    </IngestParamsProvider>
  );
};

const WithAnalytics: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const widgetContext = useWidgetContext();
  useSetupAnalytics(widgetContext);

  return <>{children}</>;
};

const WithSessionStart: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const sessionStarted = useRef(false);
  const { sessionId } = useContext(TrackingContext);
  const fireTrackingEvent = useFireTrackingEvent();

  useEffect(() => {
    if (sessionStarted.current === false) {
      fireTrackingEvent({
        event: 'SessionStart',
        sessionId: sessionId,
      });
      sessionStarted.current = true;
    }
  }, [fireTrackingEvent, sessionId]);

  return <>{children}</>;
};

export const WithConfig: React.FC = ({ ...props }) => {
  const setConsentParamsInState = useSetRecoilState(consentSelector);

  const [consentParams, setConsentParams] = useLocalStorageValue<ConsentParams>(
    '__bndl_consent_params__',
  );

  const [widgetContext, setWidgetContext] = useState<IWidgetContext | null>(
    null,
  );
  const setLocale = useSetRecoilState(localeSelector);

  const [frameSizeSelector, setFrameSizeSelector] =
    useState<FrameSizeSelector>('desktop');
  const [hostInfo, setHostInfo] = useState<HostInfo | null>(null);

  const sendMessage = useMessaging(message => {
    if (message.type === 'frameSizeChange') {
      setFrameSizeSelector(message.payload);
    }

    if (message.type === 'sendConsent') {
      const serializedConsent = serializeConsentOptions(
        message.payload as ConsentParams,
      );
      setConsentParams(serializedConsent);
    }

    if (message.type === 'overwriteConfig') {
      // Only needed for TS, the config object should be there.
      const newConfig =
        window.__DH_CONFIG != null
          ? (mergeDeepRight<WindowConfig, Partial<WindowConfig>>(
              window.__DH_CONFIG,
              message.payload,
            ) as WindowConfig)
          : window.__DH_CONFIG;

      // Also set on window
      window.__DH_CONFIG = newConfig;
      setWidgetContext(
        windowConfigToWidgetContext({
          ...widgetContext,
          windowConfig: newConfig,
          hostPageParams: widgetContext?.hostPageParams,
        }),
      );
    }
    if (message.type === 'hostInfo') {
      setHostInfo(message.payload);
    }
  });

  // Effect watching for consent updates
  useEffect(() => {
    setConsentParamsInState(
      consentParams ?? {
        ad_personalization: 'denied',
        ad_storage: 'denied',
        ad_user_data: 'denied',
        analytics_storage: 'denied',
      },
    );
  }, [consentParams]);

  useEffect(() => {
    const search = global.window.location.search;
    const { dhFrameSize, preventDefaultOpening } = qs.parse(search);
    const _frameSizeSelector: FrameSizeSelector =
      typeof dhFrameSize === 'string'
        ? (dhFrameSize as FrameSizeSelector)
        : 'desktop';

    const _preventDefaultOpening: boolean =
      typeof preventDefaultOpening === 'string'
        ? Boolean(parseInt(preventDefaultOpening))
        : false;

    setFrameSizeSelector(_frameSizeSelector ?? 'desktop');
    setWidgetContext(
      windowConfigToWidgetContext({
        windowConfig: window.__DH_CONFIG,
        hostPageParams: search,
        preventDefaultOpening: _preventDefaultOpening,
      }),
    );
    setLocale(
      getStoredLocale(window.__DH_CONFIG.accountId) ?? getBrowserLocale(),
    );

    // eslint-disable-next-line no-console
    if (window.__DH_CONFIG?.apps.length === 0 && console && console.warn) {
      // eslint-disable-next-line no-console
      console.warn(
        `[DatHuis Widget] No apps enabled, please enable some apps.`,
      );
    }
  }, []);

  // Watch for consent updates
  useEffect(() => {
    setWidgetContext(prev => {
      if (prev !== null) {
        return { ...prev, consentParams };
      }

      return prev;
    });
  }, [consentParams]);

  useEffect(() => {
    if (widgetContext !== null) {
      sendMessage({
        type: 'configLoaded',
        payload: window.__DH_CONFIG,
      });
    }
  }, [sendMessage, widgetContext]);

  if (widgetContext === null || Object.keys(widgetContext?.apps).length === 0)
    return null;

  return (
    <FrameSizeContext.Provider value={frameSizeSelector}>
      <HostInfoContext.Provider value={hostInfo}>
        <WidgetContext.Provider value={widgetContext}>
          <>
            <AppWrapper {...props}>
              <Outlet />
            </AppWrapper>
          </>
        </WidgetContext.Provider>
      </HostInfoContext.Provider>
    </FrameSizeContext.Provider>
  );
};

export default WithConfig;
