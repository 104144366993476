import * as Sentry from '@sentry/react';
import pako from 'pako';
import base64UrlToUint8Array from '../../../../utils/base64UrlToUint8Array';

export type BNDL_Data = {
  type: 'direct';
  path: string;
  data: Record<string, string>;
} | null;

const inflateQueryParams = (encodedData: string): BNDL_Data => {
  try {
    // Convert the base64url-encoded string to Uint8Array
    const compressedData = base64UrlToUint8Array(encodedData);
    // Use pako to inflate the compressed data
    const jsonString = pako.inflate(compressedData, { to: 'string' });
    // Parse the JSON string
    const decompressedObject = JSON.parse(jsonString);

    if (
      'data' in decompressedObject &&
      'path' in decompressedObject &&
      'type' in decompressedObject
    ) {
      return decompressedObject;
    }

    return null;
  } catch {
    Sentry.captureException(new Error(`Unhandled event type: ${event}`));
    return null;
  }
};

export default inflateQueryParams;
