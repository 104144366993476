const base64UrlToUint8Array = base64Url => {
  // Convert from base64url to base64
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  // Decode base64 to binary string
  const binaryString = atob(base64);
  const bytes = new Uint8Array(binaryString.length);

  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes;
};

export default base64UrlToUint8Array;
