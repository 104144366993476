import React from 'react';
import type { BNDL_Data } from '~/components/IngestParamsProvider/utils/inflateQueryParams';

export type IngestSource = 'search' | 'storage';

export type IIngestParamsContext = {
  source: IngestSource;
  bndlData: BNDL_Data;
} | null;

const IngestParamsContext = React.createContext<IIngestParamsContext>(null);

export default IngestParamsContext;
