import { atom, DefaultValue, selector } from 'recoil';

import { ConsentParams, LayoutT, Locale } from '~/shared/types';

const isDefaultValue = (value: any): value is DefaultValue =>
  value instanceof DefaultValue;

type WidgetState = {
  loading: boolean;
  expanded: boolean;
  fullscreen: boolean;
  layout: LayoutT;
  locale: Locale;
  title: string | null;
  appsOpened: Array<string>;
  consentParams: ConsentParams;
};

export const widgetState = atom<WidgetState>({
  key: 'main/widgetState',
  default: {
    expanded: false,
    fullscreen: false,
    loading: false,
    layout: 'collapsed',
    locale: 'nl',
    title: null,
    appsOpened: [],
    consentParams: {
      ad_personalization: 'denied',
      ad_storage: 'denied',
      ad_user_data: 'denied',
      analytics_storage: 'denied',
    },
  },
});

export const layoutSelector = selector<LayoutT>({
  key: 'main/layout',
  get: ({ get }) => {
    const { layout, fullscreen } = get(widgetState);
    if (fullscreen) return 'fullscreen';

    return layout;
  },
  set: ({ get, set }, newValue) => {
    if (isDefaultValue(newValue)) throw Error();
    const ws = get(widgetState);
    set(widgetState, { ...ws, layout: newValue });
  },
});

export const titleSelector = selector<string | null>({
  key: 'main/title',
  get: ({ get }) => {
    const { title } = get(widgetState);
    return title;
  },
  set: ({ get, set }, newValue) => {
    if (isDefaultValue(newValue)) throw Error();
    const ws = get(widgetState);
    set(widgetState, { ...ws, title: newValue });
  },
});

export const loadingSelector = selector<boolean>({
  key: 'main/loading',
  get: ({ get }) => get(widgetState).loading,
  set: ({ get, set }, newValue) => {
    if (isDefaultValue(newValue)) throw Error();
    const ws = get(widgetState);
    set(widgetState, { ...ws, loading: newValue });
  },
});

export const expandedSelector = selector<boolean>({
  key: 'main/expanded',
  get: ({ get }) => get(widgetState).expanded,
  set: ({ get, set }, newValue) => {
    if (isDefaultValue(newValue)) throw Error();
    const ws = get(widgetState);
    set(widgetState, { ...ws, expanded: newValue });
  },
});

export const fullscreenSelector = selector<boolean>({
  key: 'main/fullscreen',
  get: ({ get }) => get(widgetState).fullscreen,
  set: ({ get, set }, newValue) => {
    if (isDefaultValue(newValue)) throw Error();
    const ws = get(widgetState);
    set(widgetState, { ...ws, fullscreen: newValue });
  },
});

export const localeSelector = selector<Locale>({
  key: 'main/locale',
  get: ({ get }) => get(widgetState).locale,
  set: ({ get, set }, newValue) => {
    if (isDefaultValue(newValue)) throw Error();
    const ws = get(widgetState);
    set(widgetState, { ...ws, locale: newValue });
  },
});

export const appsOpenedSelector = selector<Array<string>>({
  key: 'main/appsOpened',
  get: ({ get }) => get(widgetState).appsOpened,
  set: ({ get, set }, newValue) => {
    if (isDefaultValue(newValue)) throw Error();
    const ws = get(widgetState);
    set(widgetState, { ...ws, appsOpened: newValue });
  },
});

export const consentSelector = selector<ConsentParams>({
  key: 'main/consentParams',
  get: ({ get }) =>
    get(widgetState).consentParams ?? {
      ad_personalization: 'denied',
      ad_storage: 'denied',
      ad_user_data: 'denied',
      analytics_storage: 'denied',
    },
  set: ({ get, set }, newValue) => {
    if (isDefaultValue(newValue)) throw Error();
    const ws = get(widgetState);
    set(widgetState, { ...ws, consentParams: newValue });
  },
});
