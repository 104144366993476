import { isEmpty, isNil } from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '../../../Icon';
import JustificationContainer from '../../../JustificationContainer';
import icons from '../../../Icon/components';
import { Heading4 } from '../../../Typography';
import HeaderContainer from '../HeaderContainer';
import Throbber from '../../../Throbber';
import LanguageSwitch from '../LanguageSwitch';
import { useRecoilValue } from 'recoil';
import { widgetState } from '../../../../state/widgetState';

type HeaderAction = {
  label: React.ReactNode;
  icon: keyof typeof icons;
  onClick: (event: React.MouseEvent<Element, MouseEvent>) => void;
};

export type Props = {
  dataTestId?: string;
  actions?: Array<HeaderAction>;
};

const Wide: React.FC<Props> = ({ actions }) => {
  const { loading, title } = useRecoilValue(widgetState);

  return (
    <HeaderContainer>
      <Title dataTestId="header-title">{title}</Title>
      <ActionsContainer align="center" justification="end">
        {loading === true && <Throbber />}
        <LanguageSwitch />
        {!isNil(actions) &&
          !isEmpty(actions) &&
          actions?.map(action => (
            <IconWithLabel
              key={action.label?.toString()}
              onClick={action.onClick}
              align="center"
              justification="end"
            >
              <StyledIcon name={action.icon} strokeWidth={2} />
              <StyledHeading $withoutMargin>{action.label}</StyledHeading>
            </IconWithLabel>
          ))}
      </ActionsContainer>
    </HeaderContainer>
  );
};

const Title = styled(Heading4)(
  ({ theme }) => css`
    width: 100%;
    margin: 0 0 0 ${theme.space('base')};
    font-size: ${theme.fontSize('m')};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    ${theme.mq.lessThan('mobile')`
      display:none;
    `};
  `,
);

const StyledHeading = styled(Heading4)(
  ({ theme }) => css`
    margin-left: ${theme.space('base')};
    font-weight: ${theme.fw('semiBold')};

    ${theme.mq.lessThan('mobile')`
      display: none;
    `}
  `,
);

const IconWithLabel = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    width: max-content;
    cursor: pointer;
    transition: all 0.3s ease-out;
    padding: ${theme.space('xxs')};

    &:last-child {
      margin-left: ${theme.space('s')};
    }

    * {
      color: #000;
    }
  `,
);

const ActionsContainer = styled(JustificationContainer)<{}>``;

const StyledIcon = styled(Icon)<{}>(
  ({ theme }) => css`
    cursor: pointer;
    color: #000;
    svg {
      width: ${theme.space('s')};
    }
  `,
);

export default Wide;
