import React, { useContext, useEffect, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import FrameFacade from '~/components/FrameFacade';
import JustificationContainer from '~/components/JustificationContainer';
import HeightContext from '~/context/HeightContext';
import useDebouncedCallback from '~/hooks/useDebouncedCallback';
import useLocale from '~/hooks/useLocale';
import useWidgetContext from '~/hooks/useWidgetContext';
import { layoutSelector, widgetState } from '../../state/widgetState';

const AppPage: React.FC = () => {
  const location = useLocation();
  const { headerRef, footerRef } = useContext(HeightContext);
  const [minHeight, setMinHeight] = useState<number>(300);
  const locale = useLocale();
  const [state, setWidgetState] = useRecoilState(widgetState);
  const [layout, setLayout] = useRecoilState(layoutSelector);

  const mainRef = useRef<HTMLDivElement | null>(null);
  const context = useWidgetContext();
  const { slug } = useParams();

  // Only set loading state on mount
  useEffect(() => {
    setWidgetState(prev => ({ ...prev, loading: true }));
  }, []);

  // Update title
  useEffect(() => {
    if (typeof slug === 'string' && context.apps[slug]) {
      setTimeout(() => {
        setWidgetState(prev => ({
          ...prev,
          title: context.apps[slug].title[locale],
        }));
      }, 0);
    }

    return () => {
      // Reset title when we navigate away
      setWidgetState(prev => ({
        ...prev,
        title: null,
      }));
    };
    // Update title when locale changes
  }, [locale, slug]);

  useEffect(() => {
    if (layout !== 'wide') {
      setLayout('wide');
    }
  }, [layout, location.pathname]);

  const onResize = useDebouncedCallback(() => {
    if (headerRef.current === null || footerRef.current === null) return;

    const headerBound = headerRef.current?.getBoundingClientRect();
    const footerBound = footerRef.current?.getBoundingClientRect();
    const minHeight = footerBound.y - (headerBound.y - headerBound.height);
    setMinHeight(() => minHeight - 5);
  }, 50);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  if (typeof slug !== 'string') return <div>TODO: NOT FOUND</div>;
  const app = context.apps[slug];
  if (!app) return <div>TODO: NOT FOUND</div>;

  return (
    <Main ref={mainRef}>
      <Helmet>
        <title>{state.title}</title>
      </Helmet>
      <Container justification="start" align="center">
        <FrameContainer>
          <FrameFacade app={app} minHeight={minHeight} />
        </FrameContainer>
      </Container>
    </Main>
  );
};

const Container = styled(JustificationContainer)`
  height: 100%;
`;

const Main = styled.main`
  padding: 0;
  height: 100%;
`;

const FrameContainer = styled.div`
  width: 100%;
  height: 100%;
  min-width: 100%;
  margin: 0 auto;
`;

export default AppPage;
