import { isNil, keys } from 'ramda';
import React, { useState } from 'react';
import { animated, useTransition } from 'react-spring';
import styled, { css } from 'styled-components';
import useWidgetContext from '../../../../hooks/useWidgetContext';
import { Locale } from '../../../../shared/types';
import Icon from '../../../Icon';
import { localeSelector } from '../../../../state/widgetState';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

export type Props = {
  dataTestId?: string;
};

type LanguageOption = {
  flag: JSX.Element;
  optionLabel: string;
  triggerLabel: string;
};

const options: Record<Locale, LanguageOption> = {
  nl: {
    flag: <Icon name="nlFlag" />,
    optionLabel: 'Nederlands',
    triggerLabel: 'Taal aanpassen',
  },
  en: {
    flag: <Icon name="engFlag" />,
    optionLabel: 'English',
    triggerLabel: 'Change language',
  },
};

const LanguageSwitch: React.FC<Props> = ({ dataTestId, ...rest }) => {
  const { apps } = useWidgetContext();
  const [expanded, setExpanded] = useState(false);
  const [locale, setLocale] = useRecoilState(localeSelector);
  const pageParams = useParams();
  const location = useLocation();
  const slug = pageParams.slug;

  const isOnAppPage = location.pathname.includes('apps') && !isNil(slug);
  const currentApp =
    typeof slug === 'string' && isOnAppPage ? apps[slug] : null;

  const transitions = useTransition(expanded, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      tension: 180,
      mass: 1,
      friction: 10,
      clamp: true,
    },
  });
  const supportedLangKeys = keys(options).filter(() => {
    // When no supportedLang, ensure this array is empty
    if (currentApp && !currentApp.supportedLang) {
      return false;
    }

    // Return all supported by the widget
    return true;
  });

  if (supportedLangKeys.length === 0) return null;
  const usesUnsupportedLang = !isOnAppPage
    ? false
    : !currentApp?.supportedLang?.includes(locale);

  return (
    <Container data-testid={dataTestId} {...rest}>
      <Trigger
        onClick={() => setExpanded(prev => !prev)}
        $usesUnsupportedLang={usesUnsupportedLang}
      >
        <FlagContainer>{options[locale].flag}</FlagContainer>
        <StyledIcon name="chevron-down" $expanded={expanded} strokeWidth={5} />
      </Trigger>
      {transitions(
        (styles, expanded) =>
          expanded === true && (
            <OptionList style={styles}>
              {supportedLangKeys.map(lang => (
                <Option
                  key={lang}
                  onClick={
                    !isOnAppPage || currentApp?.supportedLang?.includes(lang)
                      ? () => {
                          setLocale(lang);
                          setExpanded(false);
                        }
                      : undefined
                  }
                  $disabled={
                    (isOnAppPage &&
                      !currentApp?.supportedLang?.includes(lang)) ??
                    false
                  }
                >
                  <FlagContainer>{options[lang].flag}</FlagContainer>
                  <LabelContainer>{options[lang].optionLabel}</LabelContainer>
                </Option>
              ))}
            </OptionList>
          ),
      )}
    </Container>
  );
};

const Container = styled.div(
  ({ theme }) => css`
    position: relative;
    margin-right: ${theme.space('s')};
  `,
);

const Trigger = styled.button<{ $usesUnsupportedLang: boolean }>(
  ({ theme, $usesUnsupportedLang }) => css`
    user-select: none;
    cursor: pointer;
    border: 1px solid #bfc5cd;
    border-radius: ${theme.getSystem().border.radius.base};
    padding: ${theme.space('xxs')};
    background-color: transparent;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.3s ease-out;
    position: relative;
    color: #798697;

    &:hover {
      background: ${theme.color('secondary')};
      color: ${theme.color('secondary', 'text')};

      span,
      svg {
        color: ${theme.color('secondary', 'text')};
      }
    }

    ${$usesUnsupportedLang &&
    css`
      &:after {
        content: '';
        width: 80%;
        position: absolute;
        top: calc(50% - 1px);
        border-bottom: 1px solid ${theme.color('secondary')};
      }
    `}
  `,
);

const OptionList = styled(animated.ul)(
  ({ theme }) => css`
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #fff;

    position: absolute;
    top: 2.5rem;
    right: 0;
    border-radius: ${theme.getSystem().border.radius.s};
    box-shadow: ${theme.boxShadow('s')};
  `,
);

const Option = styled.li<{ $disabled?: boolean }>(
  ({ theme, $disabled }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: ${theme.space('xxs')} ${theme.space('xs')};
    cursor: pointer;
    position: relative;
    user-select: none;

    &:first-child {
      border-top-right-radius: ${theme.getSystem().border.radius.s};
      border-top-left-radius: ${theme.getSystem().border.radius.s};
    }

    &:last-child {
      border-bottom-right-radius: ${theme.getSystem().border.radius.s};
      border-bottom-left-radius: ${theme.getSystem().border.radius.s};
    }

    &:hover {
      background-color: ${theme.color('secondary')};

      span {
        color: ${theme.color('secondary', 'text')};
      }
    }

    ${$disabled === true &&
    css`
      background-color: #f7f7f7;
      color: ${theme.color('secondary')};
      cursor: not-allowed;

      &:hover {
        background-color: #fff;
        span {
          color: ${theme.color('secondary')};
        }
      }

      &:after {
        content: '';
        width: 80%;
        position: absolute;
        top: calc(50% - 1px);
        border-bottom: 1px solid ${theme.color('secondary')};
      }
    `}
  `,
);

const FlagContainer = styled.span(
  ({ theme }) => css`
    line-height: ${theme.lh('heading')};
    display: inline-block;
    width: 1em;
    margin-right: ${theme.space('xxs')};
  `,
);

const StyledIcon = styled(Icon)<{ $expanded: boolean }>`
  svg {
    height: 0.5em;
    width: 0.5em;
    color: #35265e;

    ${({ $expanded }) => css`
      transform: ${$expanded ? 'rotate(180deg)' : 'rotate(0deg)'};
      transition: transform 0.2s ease-out;
    `};
  }
`;

const LabelContainer = styled.span(
  ({ theme }) => css`
    font-size: ${theme.fontSize('xs')};
  `,
);

export default LanguageSwitch;
