import { isNil, keys } from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';
import useLocale from '../../hooks/useLocale';
import { DHApp } from '../../shared/types';
import { Heading3 } from '../Typography';
import ListItem from '../ListItem';
import cleanPath from '../../utils/cleanPath';
import useWidgetContext from '../../hooks/useWidgetContext';
import { useSetRecoilState } from 'recoil';
import { appsOpenedSelector } from '../../state/widgetState';

export type Props = {
  header: React.ReactNode;
  items: Record<DHApp['slug'], DHApp>;
  basePath: string;
  dataTestId?: string;
  includeHighlighted?: boolean;
};

const ServiceList: React.FC<Props> = ({
  dataTestId,
  header,
  items,
  basePath,
  includeHighlighted = false,
  ...rest
}) => {
  const context = useWidgetContext();
  const setAppsOpened = useSetRecoilState(appsOpenedSelector);
  const locale = useLocale();
  const itemKeys = keys(items);

  return (
    <Container data-testid={dataTestId} {...rest}>
      <Heading>{header}</Heading>
      <ListContainer>
        {itemKeys
          // We don't want to show highlighted app again in the list
          .filter(key =>
            includeHighlighted ? true : key !== context.highlighted.slug,
          )
          .filter(key => items[key].hidden !== true)
          .map(item => {
            // When we disable apps from CROCS not having this check breaks the preview.
            if (isNil(items[item])) return null;

            const { title, description, slug, icon } = items[item];
            const path = `${basePath}/${slug}`;
            return (
              <li key={item}>
                <ListItem
                  next={path}
                  dataTestId={`service-item-${item}`}
                  title={title[locale]}
                  description={description[locale]}
                  icon={icon}
                  onClick={() => {
                    const cleanedPath = cleanPath(path);
                    setAppsOpened(prev => {
                      if (!prev.includes(cleanedPath)) {
                        return [...prev, cleanedPath];
                      }
                      return prev;
                    });
                  }}
                />
              </li>
            );
          })}
      </ListContainer>
    </Container>
  );
};

const Container = styled.div(
  ({ theme }) => css`
    padding-top: ${theme.space('base')};
    margin: 0 ${theme.space('xxs')} ${theme.space('base')} ${theme.space('xxs')};
  `,
);

const Heading = styled(Heading3)(
  ({ theme }) => css`
    margin-left: ${theme.space('xs')};
    color: #000;
  `,
);

const ListContainer = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media (min-width: 600px) {
    flex-direction: row;
    gap: ${({ theme }) => theme.space('xs')};
    justify-content: flex-start;

    li {
      flex: 1 1 calc(50% - ${({ theme }) => theme.space('xs')});
      max-width: calc(50% - ${({ theme }) => theme.space('xs')});
    }
  }

  @media (min-width: 900px) {
    li {
      flex: 1 1 calc(33.333% - ${({ theme }) => theme.space('xs')});
      max-width: calc(33.333% - ${({ theme }) => theme.space('xs')});
    }
  }
`;

export default ServiceList;
