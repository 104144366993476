import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { IWidgetContext } from '../../context/WidgetContext';
import { isNil } from 'ramda';

import useDHFlags from '../useDHFlags';
import useWidgetContext from '../useWidgetContext';
import serializeConsentOptions from '../../utils/serializeConsentOptions';
import { useRecoilValue } from 'recoil';
import { consentSelector } from '../../state/widgetState';

// https://www.simoahava.com/analytics/cookieflags-field-google-analytics/
const COOKIE_FLAGS = 'max-age=7200;secure;samesite=none';

const VERSION = 'v1';

export const useDefaultEventOptions = () => {
  const flags = useDHFlags();
  const widgetContext = useWidgetContext();

  const anyFlagPotential = widgetContext.featureFlags.length !== 0;

  const version = anyFlagPotential
    ? flags.length !== 0
      ? `v3/${flags.map(({ name }) => name).join('+')}`
      : 'v3'
    : VERSION;

  const config = {
    cookie_flags: COOKIE_FLAGS,
    anonymize_ip: true,
    send_page_view: false,
    accountId: widgetContext.accountId,
  };

  return {
    ...config,
    version,
  };
};

const useSetupAnalytics = (widgetContext: IWidgetContext | null) => {
  const defaultConfigOptions = useDefaultEventOptions();
  const consentParams = useRecoilValue(consentSelector);

  useEffect(() => {
    if (widgetContext === null || ReactGA.isInitialized === true) return;

    window.dataLayer.push({
      event: 'gtm.init_consent',
      ad_storage: 'denied',
      analytics_storage: 'denied',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
    });

    const trackingIds = [
      widgetContext.googleAnalytics4,
      process.env.NEXT_PUBLIC_GA_ID,
    ].filter(trackingId => !isNil(trackingId));

    ReactGA.initialize(
      trackingIds.map(trackingId => ({
        trackingId,
        gtagOptions: defaultConfigOptions,
      })),
    );

    // Set default consent options before config is initialized
    ReactGA.gtag('consent', 'default', {
      ad_storage: 'denied',
      analytics_storage: 'denied',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
    });
  }, [defaultConfigOptions, widgetContext]);

  // Watch for consent updates
  useEffect(() => {
    const serializedConsentParams = serializeConsentOptions(consentParams);

    // Update consent settings for our GA ID
    ReactGA.gtag('consent', 'update', serializedConsentParams);

    // Push consent update to the dataLayer for GTM to recognize (for all tags)
    window.dataLayer.push({
      event: 'consent_update',
      ...serializedConsentParams,
    });
  }, [consentParams]);
};

export default useSetupAnalytics;
