/**
 * Helper to parse query parameters into a key-value map.
 * @returns {Record<string, string>} A record of query parameter keys and values.
 */
const parseQueryParams = (queryString: string): Record<string, string> =>
  queryString
    ? Object.fromEntries(new URLSearchParams(queryString).entries())
    : {};

export default parseQueryParams;
