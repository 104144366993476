import { Link } from 'react-router-dom';
import { isEmpty, isNil } from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '../Icon';
import JustificationContainer from '../JustificationContainer';
import ProgressiveImgIx from '../ProgressiveImgIx';
import { Heading3, Heading4 } from '../Typography';

export type Props = {
  dataTestId?: string;
  title: React.ReactNode;
  description: React.ReactNode;

  /** When not provided render without the image */
  imageUrl?: string;

  /** What page should be loaded next? e.g. /apps/bb-wc */
  next: string;
};

const HighlightedComponent: React.FC<Props> = ({
  dataTestId,
  title,
  description,
  imageUrl,
  next,
  ...rest
}) => {
  const withImage = !isNil(imageUrl) && !isEmpty(imageUrl);

  return (
    <Link to={next}>
      <Container data-testid={dataTestId} $withImage={withImage} {...rest}>
        {withImage && (
          <JustificationContainer
            align="center"
            justification="center"
            margin={['base']}
          >
            <StyledImage src={imageUrl!} width={250} />
          </JustificationContainer>
        )}

        <GradientOverlay align="end" $withImage={withImage}>
          <JustificationContainer direction="column" margin={['base', 'l']}>
            <HeadingContainer justification="space-between">
              <Heading3>{title}</Heading3>
              <StyledIcon name="arrow-right" />
            </HeadingContainer>
            <Heading4>{description}</Heading4>
          </JustificationContainer>
        </GradientOverlay>
      </Container>
    </Link>
  );
};

const BOTTOM_POSITION = 50;
const MAX_HEIGHT = 250;
const Container = styled.div<{ $withImage?: boolean }>(
  ({ $withImage, theme }) => css`
    width: 100%;
    position: relative;
    height: 100%;
    display: block;
    cursor: pointer;
    margin-bottom: ${$withImage ? BOTTOM_POSITION : 0}px;

    &:hover ${StyledImage} {
      transform: translate(0, -5px);
    }

    &:hover ${StyledIcon} {
      transform: translate(5px, 0);
      color: ${theme.color('secondary')};
    }

    &:hover ${GradientOverlay} h3,
    &:hover ${GradientOverlay} h4 {
      color: ${theme.color('secondary')};
    }

    @media (min-width: 600px) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0;
    }
  `,
);

const StyledIcon = styled(Icon)<{}>(
  ({ theme }) => css`
    color: ${theme.color('primary')};
    transition: all 0.3s ease-out;
    svg {
      height: 1em;
      width: 1em;
    }
  `,
);

const GradientOverlay = styled(JustificationContainer)<{
  $withImage?: boolean;
}>(
  ({ theme, $withImage }) => css`
    background: linear-gradient(
      180deg,
      rgba(247, 247, 247, 0) ${$withImage ? '32.95%' : '0%'},
      #f7f7f7 48%
    );
    position: ${$withImage ? 'absolute' : 'relative'};
    bottom: ${$withImage ? `-${BOTTOM_POSITION}px` : '0px'};
    width: 100%;
    height: 100%;
    color: ${theme.color('primary')};

    @media (min-width: 600px) {
      position: relative;
      background: none;
      height: auto;
      width: 60%;
      margin-right: ${theme.space('s')};
    }
  `,
);

const HeadingContainer = styled(JustificationContainer)<{}>`
  width: 100%;
`;

const StyledImage = styled(ProgressiveImgIx)<{}>(
  ({ theme }) => css`
    border: ${theme.getSystem().border.width.s} solid #dadfe6;
    box-shadow: ${theme.boxShadow('card')};
    max-height: ${MAX_HEIGHT}px;
    transition: all 0.2s ease-out;

    @media (min-width: 600px) {
      /* max-width: 40%; */
      /* max-height: unset; */
      margin-left: ${theme.space('s')};
    }
  `,
);

export default HighlightedComponent;
