import React, { useContext, useEffect, useState } from 'react';
import IngestParamsContext from '~/context/IngestParamsContext';
import inflateQueryParams, { BNDL_Data } from './utils/inflateQueryParams';
import parseQueryParams from './utils/parseQueryParams';
import useLocalStorageValue from '@bndl-io/use-local-storage';
import { isNil } from 'ramda';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useWidgetContext from '~/hooks/useWidgetContext';
import usePathPrefix from '~/hooks/usePathPrefix';
import { widgetState } from '../../state/widgetState';
import { useSetRecoilState } from 'recoil';

const INGEST_STORAGE_KEY = '__bndl_ingest_params__';

const IngestParamsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [searchParams, setSearchParams] = useState<BNDL_Data>(null);
  const [storedParams, setStoredParams] =
    useLocalStorageValue<Record<string, string>>(INGEST_STORAGE_KEY);

  useEffect(() => {
    const bndlData = parseQueryParams(window.location.search ?? '').bndlData;

    const inflated = inflateQueryParams(bndlData);
    if (bndlData && inflated) {
      setSearchParams(inflated);
    }
  }, []);

  // Watch for updates to searchParams and reflect those changes in the local storage
  useEffect(() => {
    if (!isNil(searchParams)) {
      setStoredParams(searchParams);
    }
  }, [searchParams]);

  if (window.location.search !== '' && !isNil(searchParams)) {
    return (
      <IngestParamsContext.Provider
        value={{
          bndlData: searchParams,
          source: 'search',
        }}
      >
        <BNDLPathRedirector>{children}</BNDLPathRedirector>
      </IngestParamsContext.Provider>
    );
  }

  return (
    <IngestParamsContext.Provider
      value={{
        bndlData: storedParams,
        source: 'storage',
      }}
    >
      <BNDLPathRedirector>{children}</BNDLPathRedirector>
    </IngestParamsContext.Provider>
  );
};

const BNDLPathRedirector: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const setWidgetState = useSetRecoilState(widgetState);

  const ingestParams = useContext(IngestParamsContext);
  const navigate = useNavigate();
  const { slug } = useParams();
  const location = useLocation();
  const widgetContext = useWidgetContext();
  const pathPrefix = usePathPrefix();

  useEffect(() => {
    const currentApp =
      slug && typeof slug === 'string' ? widgetContext.apps[slug] : null;

    if (
      ingestParams &&
      ingestParams.source === 'search' &&
      ingestParams.bndlData &&
      'path' in ingestParams.bndlData &&
      isNil(currentApp)
    ) {
      void navigate(
        `${pathPrefix}${ingestParams.bndlData.path}${location.search}`,
      );
      setWidgetState(prev => ({ ...prev, expanded: true, layout: 'wide' }));
    }
  }, [ingestParams]);

  return children;
};

export default IngestParamsProvider;
