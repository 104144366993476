import React from 'react';
import styled, { css } from 'styled-components';
import { SystemSize } from '../../theme/System/Default';
import arrayToCss from '../../utils/arrayToCss';

export type Props = {
  dataTestId?: string;
  margin?: Array<SystemSize | null>;
  vertical?: boolean;
};

const Divider = React.forwardRef<HTMLHRElement, Props>(
  ({ dataTestId, margin = ['m', null], vertical = false, ...rest }, ref) => (
    <Container
      ref={ref}
      data-testid={dataTestId}
      $margin={margin}
      $vertical={vertical}
      {...rest}
    />
  ),
);

Divider.displayName = 'Divider';

const Container = styled.hr<{ $margin?: Props['margin']; $vertical?: boolean }>(
  ({ theme, $margin = ['m', null], $vertical = false }) => css`
    border-radius: ${theme.getSystem().border.radius.s};
    border: 0;
    height: ${$vertical ? '100%' : '1px'};
    width: ${$vertical ? '1px' : '100%'};
    background: #bfc5cd;
    margin: ${arrayToCss($margin, theme)};
  `,
);

export default Divider;
