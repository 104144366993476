import { useRecoilValue } from 'recoil';
import { Locale } from '../../shared/types';
import { localeSelector } from '../../state/widgetState';

const useLocale: () => Locale = () => {
  const locale = useRecoilValue(localeSelector);
  return locale;
};

export default useLocale;
