import useWidgetContext from '../useWidgetContext';
import { useLocation } from 'react-router-dom';
export type LayoutVariant = 'iframe' | 'standalone';

const usePathPrefix = (): string => {
  const { accountId } = useWidgetContext();
  const location = useLocation();
  const context = useWidgetContext();
  const regexp = new RegExp(`\/-\/${accountId}\/(iframe|s)`);

  const matches = location.pathname.match(regexp);

  const variant: LayoutVariant =
    matches && matches[1] === 's' ? 'standalone' : 'iframe';

  switch (variant) {
    case 'standalone':
      return `/-/${context.accountId}/s`;
    default:
      return `/-/${context.accountId}/iframe`;
  }
};

export default usePathPrefix;
